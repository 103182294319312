
.navbar {
    top: 0;
    position: sticky;
    z-index: 1040;
}

.sidebar {
    button {
        height: 28px;
        width: 60px;
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        background: none;
        border: 0;
        text-align: center;
        outline: none;
        color: transparent;
        margin: 1rem;
        background-color: var(--theme-color1);
        @media (min-width: 768px) {
            display: none;
        }
        &.hide-menu > .content {
            opacity: 0;
        }
        &.hide-menu:before {
            transform: rotate(-45deg) translate3d(0, -50%, 0);
            top: 50%;
        }
        &.hide-menu:after {
            transform: rotate(45deg) translate3d(0, 50%, 0);
            bottom: 50%;
        }
        &:before {
            top: 0;
        }
        &:after {
            bottom: 0;
        }
        &:before, &:after, .content{
            content: "";
            position: absolute;
            width: 40px;
            height: 4px;
            background-color: #333;
            transition: all ease 0.3s;
            border-radius: 4px;
        }
    }
    @media (min-width: 768px) {
        position: sticky;
        height: calc(100vh - 3.5rem);
        top: 3.5rem;
        margin: 0;
    }
    padding: 0px;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    background-color: var(--theme-color1);
    
    a.nav-link {
        @media (min-width: 768px) {
            /*padding: 20px;
            font-size: 1.1rem;*/
        }
        font-size: 1rem;
        padding: 10px;
        color: white;
        transition: .5s background-color;
    }
    a.nav-link:hover, a.nav-link.active:hover {
        background-color: #208c83;
    }
    a.nav-link.active {
        background-color: #239e93;
        border: 1px solid #208c83;
    }
}
.card-header.sticky {
    background-color: white;
    position: sticky;
    top: 49px;
    z-index: 100;
}

.stat-widget{ 
    .stat-text {
        font-size: 16px;
        margin-bottom: 5px;
        color: #868e96;
    }
}
.stat-widget{
    .stat-digit {
        font-size: 1.75rem;
        font-weight: 500;
        color: #373757;
    }
} 

.full-width {
    width: 100%;
    & > div {
        width: 100%;
    }
}

.settings-label{
    width:100px;
    display: inline-block;
}

.custom-table {

    th, .header {
        border-bottom-color: #208c83 !important;
        background-color: #239e93;
        border-color: #208c83;
        color: white;
        font-weight: bold;
    }

    td.phono {
        padding: 0.5rem 0.2rem;
        background-color: white;
        input {
            outline: none;
            border: none;
            padding: 0.3rem;
            border-bottom: 1px solid #208c83;
            font-size: 0.9rem;
        }
    }

}

.panel {
    .header {
        padding: 0.8rem;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        border-bottom: 2px solid white;
        background: rgb(23,115,107);
        background: linear-gradient(152deg, rgba(23,115,107,1) 40%, rgba(56,196,184,1) 84%);
        h5 {
            margin-bottom: 0;
            cursor: default;
            color: white;
            font-weight: bold;
        }
    }
    .content {
        box-shadow: 0px 1px 5px 1px #efefef;
    }
    margin-bottom: 1rem;
}

.specialization-badge {
    margin-right: 4px;
}

.specialization-tool {
    font-size: 80%;
    font-style: italic;
    font-weight: normal;
}

.card-header {
    margin-bottom: 0;
    font-size: 1.1rem;
    font-weight: 400;
}

.card-header > div {
    font-size: 1.1rem;
}
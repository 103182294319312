
@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/OpenSans-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/OpenSans-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/OpenSans-Bold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/OpenSans-SemiBold.ttf');
  font-weight: 700;
}

main {
  padding: 20px 10px;
}

fieldset {
  background-color: #fafafa;
  /*box-shadow: 1px 1px 1px 0px #dedede;*/
  border: 1px solid #dedede;
  padding: 10px;
  border-radius: 3px;
  flex-grow: 1;
}

fieldset:not(:first-of-type){
  margin-left: 20px;
}

legend {
  margin: 0px 10px;
  font-weight: 600;
  font-size: 1rem;
  text-transform: uppercase;
  color: var(--theme-color1);
  width: auto;
}

.notified {
  box-shadow: 0px 0px 6px 2px #00BFA5;
}

.action-menu {
    margin-bottom: 1rem;
    padding: 0.5rem;
    border-radius: 3px;
    background-color: #fafafa;
    border: 1px solid #ddd;
    box-shadow: none;
}

.action-menu .form-group {
  margin-bottom: 0;
}

.action-menu button:not(:last-of-type), .action-menu a:not(:last-of-type) {
  margin-right: 1rem;
}
.react-datepicker-wrapper, .rbt {
  width: 100%;
}

.login-version {
  text-align: center;
  position: relative;
  top: -40px;
}

.app-version {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 1000;
  background-color: #343a40 !important;
  color: white;
  padding: 8px;
  font-size: 75%;
  cursor: pointer;
}

.app-version > span {
  margin-left:8px;
  color: #ffc107;
}

.panel .content {
  position: relative;
  border: 1px solid #ccc;
  border-top: 0;
}

.panel .header {
  border-bottom: 0;
  box-shadow: none;
  background: var(--theme-color1) !important;
}

.badge.badge-dark {
  background-color: var(--theme-color1);
}

h3, .h3 {
  font-size: 1.75rem;
}

h2 > .badge {
  font-weight: 400;
  font-size: 70% !important;
  position: relative;
  top: -2px;
}

h3 > .badge {
  font-weight: 400;
  font-size: 70% !important;
  position: relative;
  top: -2px;
}

h4 > .badge {
  font-weight: 400;
  font-size: 70% !important;
  position: relative;
  top: -2px;
}

h5 > .badge {
  font-weight: 400;
  font-size: 85% !important;
  position: relative;
  top: -1px;
}

.badge.badge-male, .badge.badge-female {
  color: #fff;
  background-color: #6c757d
}

label.col-form-label {
  font-weight: normal !important;
  color: #888;
  padding-right: 0.25rem;
}

.col-form-label {
  padding-top: 0;
  padding-bottom: 0;
  line-height: normal;
}

.form-value {
  font-weight: 600;
}

.form-group {
  margin-bottom: 0.4rem;
}

.form-group.row > div.col-sm-1 > p, .form-group.row > div.col-sm-2 > p, .form-group.row > div.col-sm-3 > p, .form-group.row > div.col-sm-4 > p, .form-group.row > div.col-sm-5 > p, .form-group.row > div.col-sm-6 > p, .form-group.row > div.col-sm-7 > p, .form-group.row > div.col-sm-8 > p, .form-group.row > div.col-sm-9 > p, .form-group.row > div.col-sm-10 > p, .form-group.row > div.col-sm-11 > p, .form-group.row > div.col-sm-12 > p {
  margin-bottom: 0;
}

.fieldsets {
  margin-bottom: 20px;
  margin-top: 20px;
}

.btn-xs {
  padding: 0 2px 0 2px;
}

.card-header.sticky {
  background-color: rgba(0, 0, 0, 0.03) !important;
}

.btn-logs {
  position: relative;
  top: -0.3rem;
  left: 0.3rem;
}

.user-table-container table td:nth-child(1), .user-table-container table td:nth-child(6) {
  width: 1.7rem;
  min-width: 1.7rem;
}

.minimal-td {
  width: 1.7rem;
  min-width: 1.7rem;
}

.indicator  {
  border: 1px solid #eee;
}

.services {
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: 2px 2px 4px 2px #ccc;
}
